<template>
	<div class="register">
		<simple-header title="找回密码" :isLrShow="true"></simple-header>
		<div class="findpwd container-fluid" style="height:460px">
			<div class="row justify-content-center pt-4 pb-4 h-100 align-items-center">
				<div class="border rounded p-4 h-100 bg-white" style="width: 800px;">
					<form>
						<p>如果您的账号可通过手机号码登录，请输入手机号码重设登录密码，若你忘记账号，请联系客服申述。</p>
						<b-form-input class="mt-4 rounded" v-model="phone" placeholder="请输入您绑定的手机号码"
							maxlength='11' autocomplete='off'></b-form-input>
						<div class="clearfix mt-4">
							<div class="float-left" style="width: 68%;">
								<b-form-input type="text" class="rounded w-100" v-model="phoneCode" placeholder="短信验证码"
									maxlength='6' autocomplete='off'></b-form-input>
							</div>
							<div></div>
							<div class="float-right w-30">
                                <b-button class="w-100" @click="getCode"
									:variant="seconds == 0 ? 'success' : 'secondary'"
									:disabled="seconds > 0">{{seconds == 0 ? '获取动态码' : seconds + 'S后重试'}}
								</b-button>
							</div>
						</div>
						<b-form-input class="rounded mt-4" type="password" v-model="passWord" placeholder="请输入您的新密码 (8-16位字母/数字/符号)"
							maxlength='16' autocomplete='off'></b-form-input>
						<b-button class="mt-4 w-100" variant="success"  @click="findPwd">立即找回</b-button>
					</form>
				</div>
			</div>
		</div>
		<icp-footer></icp-footer>
	</div>
</template>

<script>
	import simpleHeader from '@/components/header/simple-header';
	import icpFooter from '@/components/footer/icp-footer';
	export default {
		name: 'passwordfind',
		components: {
			icpFooter,simpleHeader
		},
		data(){
		    return {
                text:'',
                passWord:'',
                phone:'',
                phoneCode:'',
                seconds: 0,
                timer: null,
            }
		},
		methods:{
            verifyMobile() {
				if (this.phone == '') {
                    this.$model.toast('请输入绑定的手机号码',2000)
					return false
				} else {
					if (!this.$test.mobile(this.phone)) {
                        this.$model.toast('请输入正确的手机号码',2000)
						return false
					}
				}
				return true;
			},
            verify() {
                if(!this.verifyMobile()) {
                    return false;
                }
                if (this.phoneCode == "") {
                    this.$model.toast('请输入短信验证码',2000)
                    return false;
                } 
                if (this.passWord == "") {
                    this.$model.toast('请输入您的新密码',2000)
                    return false;
                } else {
					 if(this.passWord.length < 8) {
                        this.$model.toast('密码至少8～16位，英文字母、数字或符号',2000)
                        return false;
                    }
				}
                return true;
            },
            getCode() {
				if (this.verifyMobile()) {
					this.seconds = 60;
					this.timer = setInterval(() => {
						// 执行加或减函数
						if (this.seconds > 0) {
							this.seconds--;
						} else {
							clearInterval(this.timer);
						}
					}, 1000);
				}
			},
            findPwd() {
                if(this.verify()) {
                    this.$http .post(this.$api.user.findpwd, {
                        password: this.passWord,
                        phone: this.phone,
                        phonecode:this.phoneCode
                    }).then((res) => {
                        if (res.code == 1) {
                            this.$model.toast('密码找回成功!', 2000);
                            Object.assign(this.$data, this.$options.data());
                        }
                    });
                }
            },
		}
	}
</script>


<style scoped>
	.findpwd {
		height: calc(100vh - 172px);
		min-height: calc(100vh - 172px);
		background-color: #F7F8FA;
	}
	form {
		margin: 30px auto;max-width: 480px;
	}
	@media screen and (max-width:506px) {
		.findpwd {
			min-width: 506px;
		}
	}
</style>
